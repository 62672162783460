﻿import { getElements } from "./carousel";
import { Accordion } from 'flowbite';
import $ from "jquery";



const accordionElement = getElements(
    "compare-accordion"
);
const accordionItems = new Array();
const accordionHeaderElements = getElements(
    "compare-accordion-header"
);



accordionHeaderElements.forEach((element) => {

    let id = element.dataset.id
    let accordionElement = element.dataset.bodyId;
    let accordionId = element.dataset.accordionId;
    let activeFlag = element.dataset.active == "True" ? true : false;

    let accordionItem = {
        id: id,
        triggerEl: document.querySelector("#" + id),
        targetEl: document.querySelector("#" + accordionElement),
        accordionId: accordionId,
        active: activeFlag
    }
    //console.log(accordionItem);
    accordionItems.push(accordionItem);
});

//console.log(accordionItems);

$(document).on('click', '.compare-accordion-header', function (e) {

    //add accordion background class if div is being closed
    var bodyId = e.currentTarget.dataset.bodyId;

    var accordionId = e.currentTarget.dataset.accordionId;

    var openDiv = $("#" + bodyId).hasClass("hidden");

    if (openDiv) {
        $("#" + accordionId).addClass("bg-[#182125]");
        $("#" + accordionId).removeClass("bg-[#22373c]")

    }
    else {
        $("#" + accordionId).removeClass("bg-[#182125]")
        $("#" + accordionId).addClass("bg-[#22373c]")
    }

    $(e.target.dataset.accordionTarget).addClass("bg-[#22373c]");
});

const accordionOptions = {
    alwaysOpen: false,
    activeClasses: 'bg-[#22373c] active',
    inactiveClasses: 'bg-[#182125] inactive',
    onOpen: (item) => {
        console.log('accordion item has been shown');
        console.log(item);

        //item._accordionEl.classList.add("bg-[#22373c]");
        //item._accordionEl.classList.remove("bg-[#182125]");

    },
    onClose: (item) => {
        console.log('accordion item has been hidden');
        console.log(item);

    }
};

accordionElement.forEach((element) => {
   
    var currentAccordItems = accordionItems.filter(item => item.accordionId == element.id);
    console.log(currentAccordItems);

    /*
* accordionEl: HTML element (required)
* accordionItems: array of accordion item objects (required)
* options (optional)
* instanceOptions (optional)
*/
    const accordion = new Accordion(element, accordionOptions, accordionOptions);
});


$(document).on('click', '.compareLayoutTabLinks', function (e) {
    console.log(e);
    e.preventDefault();

    var buttonActiveColor = "bg-[#caa581]";

    var layoutID = e.target.getAttribute("data-id");
    var layoutContentID = e.target.getAttribute("data-content-id");
    var layoutImageID = e.target.getAttribute("data-image-id");
    var guid = e.target.getAttribute("data-guid");

    var tabContent = "layoutTabContent-" + guid;
    var imageContent = "layoutTabImage-" + guid;
    var tabLinks = "compareLayoutTabLinks-" + guid;

    var i, tabcontent, tablinks, tabimages;

    tabcontent = document.getElementsByClassName(tabContent);
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    tabimages = document.getElementsByClassName(imageContent);
    for (i = 0; i < tabimages.length; i++) {
        tabimages[i].style.display = "none";
    }

    tablinks = document.getElementsByClassName(tabLinks);
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(buttonActiveColor, "");
    }
    //tabimages = document.getElementsByClassName("tabimage");
    //for (i = 0; i < tabimages.length; i++) {
    //    tabimages[i].style.display = "none";
    //}
    document.getElementById(layoutContentID).style.display = "block";
    document.getElementById(layoutImageID).style.display = "block";
    document.getElementById(layoutID).className += "text-white bg-[#caa581]";

});


