﻿import $ from "jquery";

//function openTab(event, tabName) {
//    var i, tabcontent, tablinks, tabimages;
//    tabcontent = document.getElementsByClassName("tabcontent");
//    for (i = 0; i < tabcontent.length; i++) {
//        tabcontent[i].style.display = "none";
//    }
//    tablinks = document.getElementsByClassName("tablinks");
//    for (i = 0; i < tablinks.length; i++) {
//        tablinks[i].className = tablinks[i].className.replace(" text-white bg-gray-800", "");
//    }
//    //tabimages = document.getElementsByClassName("tabimage");
//    //for (i = 0; i < tabimages.length; i++) {
//    //    tabimages[i].style.display = "none";
//    //}
//    document.getElementById(tabName).style.display = "block";
//    //document.getElementById(tabName + "Img").style.display = "block";
//    event.currentTarget.className += " text-white bg-gray-800";
//}


$(document).on('click', '.layoutTabLinks', function (e) {
    e.preventDefault();

    var buttonActiveColor = "bg-[#caa581]";

    var layoutID = e.target.getAttribute("data-id");
    var layoutContentID = e.target.getAttribute("data-content-id");
    var layoutImageID = e.target.getAttribute("data-image-id");

    var i, tabcontent, tablinks, tabimages;
  
    tabcontent = document.getElementsByClassName("layoutTabContent");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    tabimages = document.getElementsByClassName("layoutTabImage");
    for (i = 0; i < tabimages.length; i++) {
        tabimages[i].style.display = "none";
    }
    
    tablinks = document.getElementsByClassName("layoutTabLinks");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(buttonActiveColor, "");
    }
    //tabimages = document.getElementsByClassName("tabimage");
    //for (i = 0; i < tabimages.length; i++) {
    //    tabimages[i].style.display = "none";
    //}
    document.getElementById(layoutContentID).style.display = "block";
    document.getElementById(layoutImageID).style.display = "block";
    document.getElementById(layoutID).className += "text-white bg-[#caa581]";

});

