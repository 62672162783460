import { Splide } from "@splidejs/splide";

import {
  optionsGridImageCarouselRow,
  optionsGridSliderWithImageAndTextRow_Text,
  optionsGridImageWithHoverTextCarouselRow,
} from "../config/carousel.options";

//  Default splide options
Splide.defaults = {
  type: "loop",
  mediaQuery: "min",
  perPage: 1,
};

const gridImageCarouselRow_Elements = getElements("js--gridImageCarouselRow");
gridImageCarouselRow_Elements.forEach((item) => {
  let splide = new Splide(item, optionsGridImageCarouselRow);
  splide.mount();
});

const gridImageWithHoverTextCarouselRow_Element = getElements(
  "js--gridImageWithHoverTextCarouselRow"
);
gridImageWithHoverTextCarouselRow_Element.forEach((item) => {
  let splide = new Splide(item, optionsGridImageWithHoverTextCarouselRow);
  splide.mount();
});

const gridSliderWithImageAndTextRow_Elements = getElements(
  "js--gridSliderWithImageAndTextRow--wrapper"
);
gridSliderWithImageAndTextRow_Elements.forEach((item, i) => {
  let primaryId = item.dataset.idprimary;
  let secondaryId = item.dataset.idsecondary;
  let primarySplide = new Splide(primaryId);
  let secondarySplide = new Splide(
    secondaryId,
    optionsGridSliderWithImageAndTextRow_Text
  );

  primarySplide.sync(secondarySplide);
  primarySplide.mount();
  secondarySplide.mount({ slideNumberExtension });
});

export function getElements(className) {
  return Array.from(document.getElementsByClassName(className));
}

function slideNumberExtension(Splide, Components) {
  const { track } = Components.Elements;
  let element;
  function mount() {
    element = document.createElement("div");
    element.classList.add(
      "mt-2",
      "ml-4",
      "text-white",
      "text-sm",
      "font-gibson",
      "font-semibold",
      "tracking-[1.5px]",
      "md:tracking-[4.5px]"
    );
    track.parentElement.insertBefore(element, track.nextSibling);
    update();
    Splide.on("move", update);
  }

  function update() {
    element.textContent = `${Splide.index + 1}/${Splide.length}`;
  }

  return {
    mount,
  };
}
