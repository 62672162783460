const GOOGLE_API_KEY = "AIzaSyCAJenhvW04vcElA7ZUcdY1bR1hd7beP88";
const GOOGLE_API = "https://www.google.com/maps/search/?api=1&query=";
const googleEventApiUrl = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&callback=initEventMap&loading=async`;
const svgPin = (color = "black") =>
  `./../../.././src/assets/images/pin/${color}.svg`;
function position(lat, lng) {
  return {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };
}

//#region Google Map options
var newMapStyleJson = [
    {
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#212121"
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#212121"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#caa581"
            }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#bdbdbd"
            }
        ]
    },
    {
        "featureType": "administrative.neighborhood",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#caa581"
            }
        ]
    },
    {
        "featureType": "landscape.natural.terrain",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "poi",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "poi.attraction",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.business",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.government",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.medical",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "stylers": [
            {
                "color": "#caa581"
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#181818"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#caa581"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.place_of_worship",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.school",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.sports_complex",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#3c3c3c"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#4e4e4e"
            }
        ]
    },
    {
        "featureType": "road.local",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#3d3d3d"
            }
        ]
    }
];
var googleMapOptions = {
    zoom: 5,
    center: { lat: 39.8097343, lng: -98.5556199 },
    restriction: {
        latLngBounds: {
            north: 49.382808,
            south: 24.521208,
            east: -66.945392,
            west: -124.736342,
        },
    },
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: true,
    fullscreenControl: false,
    styles: newMapStyleJson,
};

//#endRegion

function addEventScript(url) {
  //check if script already added
  if (document.head.querySelector(`script#google-event-map-script`) != null) return;
  const mapScript = document.createElement("script");
  mapScript.id = "google-event-map-script";
  mapScript.src = url;
  mapScript.async = true;
  document.head.appendChild(mapScript);
}

const mapElem = document.getElementById("eventsPg__googleMap");
const markerElems = document.querySelectorAll("li dialog [data-google-map]");

if (mapElem != null) {
    window.addEventListener("DOMContentLoaded", addEventScript(googleEventApiUrl));

}
window.initEventMap = function () {
    console.log("events page")
    if (markerElems.length && mapElem) {
    showGoogleMap();
  }
};

function showGoogleMap() {
  const experianceCenterMap = new google.maps.Map(mapElem, googleMapOptions);
  Array.from(markerElems).forEach((item) => {
    const { uniqueId, latitude, longitude, pinColor } = item.dataset;
    const modalMap = new google.maps.Map(item, {
      ...googleMapOptions,
      center: position(latitude, longitude),
    });
    const modalMarker = new google.maps.Marker({
      map: modalMap,
      position: position(latitude, longitude),
      icon: svgPin(pinColor),
    });
    const modal = document.getElementById(`eModal-${uniqueId}`);
    const marker = new google.maps.Marker({
      map: experianceCenterMap,
      position: position(latitude, longitude),
      icon: svgPin(pinColor),
    });
    marker.addListener("click", () => {
      modal.showModal();
      modal.scroll({ top: 0 });
      modal
        .querySelector("span.absolute")
        .addEventListener("click", () => modal.close(), { once: true });
    });
  });
}
