export const optionsGridImageCarouselRow = {
  padding: 0,
  arrowPath:
    "M30.347 18.695 12.182.532a1.817 1.817 0 0 0-2.569 2.569l16.88 16.88-16.879 16.88a1.817 1.817 0 0 0 2.569 2.569l18.164-18.165a1.816 1.816 0 0 0 0-2.569z",
  breakpoints: {
    991: {
      padding: "25%",
    },
  },
  rewind: true,
  classes: {
    pagination: "splide__pagination static py-4 border border-[#707070]/[0]",
    page: "splide__pagination__page bg-white",
    arrow:
      "splide__arrow bg-[#182125]/[.7] rounded-none text-white border border-[#182125]/[.7] flex item-center justify-center w-12 h-16 md:w-14 md:h-16",
    prev: "splide__arrow--prev left-0",
    next: "splide__arrow--next right-0",
  },
};

export const optionsGridSliderWithImageAndTextRow_Text = {
  type: "loop",
  pagination: false,
  isNavigation: true,
  arrowPath:
    "M37.5 20.125a1.1 1.1 0 0 0 0 -0.288v-0.15a1.388 1.388 0 0 0 -0.15 -0.338s0 0 0 -0.063a1.137 1.137 0 0 0 -0.3 -0.3l-12.5 -8.75a1.25 1.25 0 0 0 -1.425 2.05L32.287 18.75H3.75a1.25 1.25 0 0 0 0 2.5h28.537l-9.25 6.475a1.25 1.25 0 0 0 -0.313 1.737A1.25 1.25 0 0 0 23.75 30a1.25 1.25 0 0 0 0.712 -0.225l12.5 -8.75a1.25 1.25 0 0 0 0.313 -0.325 1.163 1.163 0 0 0 0.063 -0.125 0.862 0.862 0 0 0 0.163 -0.2 0.213 0.213 0 0 0 0 -0.088 0.862 0.862 0 0 0 0 -0.163Z",
  classes: {
    arrow: "splide__arrow static translate-y-0 h-auto w-5",
    prev: "splide__arrow--prev",
    next: "splide__arrow--next",
  },
  perMove: 1,
};

export const optionsGridImageWithHoverTextCarouselRow = {
  type: "slide",
  trimSpace: true,
  arrowPath:
    "M30.347 18.695 12.182.532a1.817 1.817 0 0 0-2.569 2.569l16.88 16.88-16.879 16.88a1.817 1.817 0 0 0 2.569 2.569l18.164-18.165a1.816 1.816 0 0 0 0-2.569z",
  perPage: 1,
  perMove: 1,
  breakpoints: {
    991: {
      perPage: 2,
      focus: 0,
      padding: { right: "18%" },
    },
  },
  classes: {
    pagination: "splide__pagination static py-4 border border-[#707070]/[0]",
    page: "splide__pagination__page bg-white",
    arrow:
      "splide__arrow bg-[#182125]/[.7] rounded-none text-white border border-[#182125]/[.7] flex item-center justify-center w-12 h-16 md:w-14 md:h-16",
    prev: "splide__arrow--prev left-0",
    next: "splide__arrow--next right-0",
  },
  rewind: true,
};
