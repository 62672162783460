export function playPause(event) {
    event.preventDefault();
    let videoContainer = event.target.closest(".js--video-container");
    let videos = videoContainer.querySelectorAll("video");
    videos.forEach(video => {
        let playBtn = videoContainer.querySelector(".playBtn");
        let pauseBtn = videoContainer.querySelector(".pauseBtn");
        if (video.paused) {
          playBtn.style.display = "none";
          pauseBtn.style.display = "block";
          video.play();
        } else {
          pauseBtn.style.display = "none";
          playBtn.style.display = "block";
          video.pause();
        }
    });
   }