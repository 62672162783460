﻿export async function getRequest(url) {
  try {
    const response = await fetch(url);
    if (response.status === 200) {
      const data = await response.json();
      // console.log("Success:",response,data);
      return data;
    } else {
      console.log("Server Error", response);
    }
  } catch (error) {
    console.log("Error", error);
  }
}

export async function showModal(e) {
  e.preventDefault();
  const self = e.currentTarget;
  const modal = self.parentElement.querySelector("dialog");
  modal.showModal();
  modal.scroll({ top: 0 });
  modal.querySelector("span.absolute").addEventListener("click",
    () => {
      modal.close();
    },
    { once: true }
  );
}

export function debounce(func, wait) {
  let timeout;
  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(func, wait);
  };
 }