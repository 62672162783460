﻿import $ from "jquery";
var $menu = document.getElementById("navbar-burger-menu");
const $main = document.getElementById("main");

//var $hamburgerMenuButtn = document.getElementById("navbar-burger-button");
$(document).on('click', '#navbar-burger-button', function (e) {
    e.preventDefault();
    $menu.classList.toggle("hidden");
    $main.classList.toggle("blur");

});
$(document).on('click', '#navbar-burger-menu-close', function (e) {
    e.preventDefault();
    hideAllElementsAndStates();
    $menu.classList.toggle("hidden");
    $main.classList.toggle("blur");
});
$(document).on('click', '.navigation-item-link-primary', function (e) {
    e.preventDefault();
    var guid = e.currentTarget.dataset.guid;
    hideAllElementsAndStates();
    e.currentTarget.dataset.active = true;
    var $secondaryMenuCurrent = document.getElementById("nav-secondary-menu-" + guid);
    $secondaryMenuCurrent.classList.toggle("hidden")
});
$(document).on('click', '.navbar-burger-secondary-menu-close', function (e) {
    e.preventDefault();
    hideAllElementsAndStates();
});

function hideAllElementsAndStates() {
    var $primaryMenus = document.getElementsByClassName("navigation-item-link-primary");
    for (var i = 0; i < $primaryMenus.length; i++) {
        $primaryMenus[i].dataset.active = false;
    }
    var $secondaryMenus = document.getElementsByClassName("navigation-secondary-menu");
    for (var i = 0; i < $secondaryMenus.length; i++) {
        $secondaryMenus[i].classList.add("hidden");
    }
}

