﻿import $ from "jquery";
import { Accordion } from 'flowbite';
import { Modal } from 'flowbite';
import { initFlowbite } from 'flowbite'



let mapCanvas;
//#region Google Map json
var mapStyleJson = [
    {
        stylers: [
            {
                color: "#4285ea",
            },
        ],
    },
    {
        elementType: "geometry",
        stylers: [
            {
                color: "#6f7477",
            },
        ],
    },
    {
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#6f7477",
              
            }, {
                saturation: "-100",
            }
        ],
    },
    {
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#b5af69",
            },
            {
                visibility: "off",
            },
        ],
    },
    {
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#616161",
            },
        ],
    },
    {
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#f5f5f5",
            },
        ],
    },
    {
        featureType: "administrative",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "administrative.country",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.country",
        elementType: "geometry.stroke",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "administrative.land_parcel",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#bdbdbd",
            },
        ],
    },
    {
        featureType: "administrative.locality",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.neighborhood",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.province",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "administrative.province",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#ecdc27",
            },
        ],
    },
    {
        featureType: "administrative.province",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#050505",
            },
        ],
    },
    {
        featureType: "landscape",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "poi",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
            {
                color: "#eeeeee",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#757575",
            },
        ],
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
            {
                color: "#e5e5e5",
            },
        ],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#9e9e9e",
            },
        ],
    },
    {
        featureType: "road",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [
            {
                color: "#7f8487",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#7f8487",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
            {
                color: "#6f7477",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#6f7477",
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#6f7477",
            },
        ],
    },
    {
        featureType: "transit",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
            {
                color: "#e5e5e5",
            },
        ],
    },
    {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
            {
                color: "#eeeeee",
            },
        ],
    },
    {
        featureType: "water",
        stylers: [
            {
                color: "#292e31",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [
            {
                color: "#292e31",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#292e31",
            },
        ],
    },
];

var newMapStyleJson = [
    {
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#212121"
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#212121"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#caa581"
            }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#bdbdbd"
            }
        ]
    },
    {
        "featureType": "administrative.neighborhood",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#caa581"
            }
        ]
    },
    {
        "featureType": "landscape.natural.terrain",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "poi",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "poi.attraction",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.business",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.government",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.medical",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "stylers": [
            {
                "color": "#caa581"
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#181818"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#caa581"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.place_of_worship",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.school",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.sports_complex",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#3c3c3c"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#4e4e4e"
            }
        ]
    },
    {
        "featureType": "road.local",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#3d3d3d"
            }
        ]
    }
];
var googleMapOptions = {
    zoom: 5,
    center: { lat: 39.8097343, lng: -98.5556199 },
    restriction: {
        latLngBounds: {
            north: 49.382808,
            south: 24.521208,
            east: -66.945392,
            west: -124.736342,
        },
    },
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: true,
    fullscreenControl: false,
    styles: newMapStyleJson,
};


const dealerAccordionOptions = {
    alwaysOpen: false,
    activeClasses: 'bg-[#2a3336] text-white',
    inactiveClasses: 'bg-balise-black text-white',
    onOpen: (item) => {
    },
    onClose: (item) => {
    },
    onToggle: (item) => {
    },
};

const contactDealerModalOptions = {
    placement: 'center-center',
    backdrop: 'static',
    backdropClasses:'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
    closable: false,
    onHide: () => {
        console.log('modal is hidden');
    },
    onShow: () => {
        console.log('modal is shown');
    },
    onToggle: () => {
        console.log('modal has been toggled');
    },
};

var GOOGLE_API_KEY = "";

// instance options object
const contactDealerModalInstanceOptions = {
    id: 'contact-dealer-modal',
    override: true
};

const GOOGLE_API_KEY_ID = document.getElementById("dealer-map-api-key")
if (GOOGLE_API_KEY_ID != null) {
    GOOGLE_API_KEY = GOOGLE_API_KEY_ID.dataset.key;
}
const googleApiUrl = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&callback=initDealerMap&loading=async`;
const dealerMapElem = document.getElementById("dealer-map-canvas");
function generateGUID() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
}
//if (mapElem != null) {
//    addScript(googleApiUrl);
//}
function addScript(url) {
    //check if script already added
    if (document.head.querySelector(`script#google-dealer-map-script`) != null) return;
    const mapScript = document.createElement("script");
    mapScript.id = "google-dealer-map-script";
    mapScript.src = url;
    mapScript.async = true;
    document.head.appendChild(mapScript);
}

window.initDealerMap = function () {
    if (dealerMapElem != null) {
        loadDealerGoogleMap(dealerMapElem);
    }
};

function handleLocationError(browserHasGeolocation, pos) {
  
    alert("Error: Your browser doesn't support geolocation.")
}
export function loadDealerGoogleMap(dealerMapElem) {
    const dealerLookupMap = new google.maps.Map(dealerMapElem, googleMapOptions);
}

$(document).ready(function () {
    initFlowbite();
    //$(document).on('click', '#get_current_location-button', function (e) {
    //    e.preventDefault();
    //    if (navigator.geolocation) {
    //        navigator.geolocation.getCurrentPosition(
    //            (position) => {
    //                const pos = {
    //                    lat: position.coords.latitude,
    //                    lng: position.coords.longitude,
    //                };
    //                document.getElementById("location-search").value = "";
    //                dealerLocationWithMiles("", pos.lat, pos.lng);
    //            },
    //            () => {
    //                handleLocationError(true, map.getCenter());
    //            },
    //        );
    //    } else {
    //        // Browser doesn't support Geolocation
    //        handleLocationError(false, map.getCenter());
    //    }

    //});
    $(document).on('click', '.contact-dealer-button', function (e) {
        e.preventDefault();
        var dealerName = e.currentTarget.dataset.dealerName;
        var dealerNumber = e.currentTarget.dataset.dealerNumber;
        var uniqueKey = e.currentTarget.dataset.uniqueKey;
        $("#cfr_mainform").find(".lead_dealer_number").val(dealerNumber);
        $("#cfr_mainform").find(".lead_web_dealer_name").val(dealerName);
        $("#cfr_mainform").find(".lead_dealer_clientele_key").val(uniqueKey);
        var modalTarget = e.currentTarget.dataset.modalTarget;
        const modalInstance = FlowbiteInstances.getInstance('Modal', modalTarget);
        modalInstance.show();
    });

    $("#location-search").on('keyup', function (e) {
        var $LocationId = $("#location-search");
        var zipCode = e.currentTarget.value;
        var length = $LocationId.val().length;
        if (e.key === 'Enter' || e.keyCode === 13) {
        }
        if (length >= 5) {
            getDealerLocationData(zipCode);
        }
    });
    var getDealerLocationData = function (zipCode) {
        $("#dealer-location-list").empty();

        var spinnerHTML = '<div class="flex justify-center text-center">'+
            '<div role="status">' +
            '<svg aria-hidden="true" class="w-8 h-8 text-balise-orange-600 animate-spin fill-balise-orange" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">' +
            '<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="white"/>' +
            '<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>' +
            '</svg>' +
            '<span class="sr-only">Loading...</span>' +
            '</div>' +
            '</div>';
        $(spinnerHTML).appendTo("#dealer-location-list");

        var pin = ' `./../../.././src/assets/images/MapPin.png';
        // Google has tweaked their interface somewhat - this tells the api to use that new UI
        google.maps.visualRefresh = true;

        // This makes the div with id "map_canvas" a google map
        var map = new google.maps.Map(dealerMapElem, googleMapOptions);

        // put in some information about each json object - in this case, the opening hours.
        var infowindow;
        var markers = [];
        var country = "United States";

        $.ajax({
            url: "/umbraco/api/SalesForceAPI/GetDealers?country=" + country +"&zip=" + zipCode,
            type: "GET",
            dataType: 'json',
            success: function (data) {
                var mapBounds = map.getBounds();
                if (data.length > 0) {
                    $("#dealer-location-list").empty();
                    var LatLngList = [];
                    $.each(data, function (i, item) {

                        var lat = parseFloat(item.latitude);
                        var lng = parseFloat(item.longitude);
                        var LatLng = new google.maps.LatLng(lat, lng);
                        LatLngList.push(LatLng);
                        var marker = new google.maps.Marker({
                            position: new google.maps.LatLng(lat, lng),
                            map: map,
                            title: item.name,
                            icon: pin
                        });
                        markers.push(marker);

                        // finally hook up an "OnClick" listener to the map so it pops up out info-window when the marker-pin is clicked!
                        google.maps.event.addListener(marker, 'click', function () {
                            if (infowindow) infowindow.close();
                            infowindow = new google.maps.InfoWindow({ content: "<div class='flex flex-col justify-center items-center'><h5>" + item.name + "</h5><br/><span>" + item.street + "</span><span>" + item.city + ", " + item.state + "</span><span>" + item.zipcode + "</span></div>" });
                            infowindow.open(map, marker);
                        });
                    });
                    var { hmtl, accordionData } = generateDealerAccordionList(data);
                    $(hmtl).appendTo("#dealer-location-list");
                    var accordionConfig = buildLocationAccordionConfig(accordionData);
                    const accordion = new Accordion(accordionConfig.accordionElement, accordionConfig.accordionItems, accordionConfig.options, accordionConfig.instanceOptions);
                    var bounds = new google.maps.LatLngBounds();
                    for (var i = 0, LtLgLen = LatLngList.length; i < LtLgLen; i++) {
                        //  And increase the bounds to take this point
                        bounds.extend(LatLngList[i]);
                    }

                    //  Fit these bounds to the map
                    map.fitBounds(bounds);
                    map.setCenter(bounds.getCenter());

                    //map.fitBounds(mapBounds);
                }
                else {
                    $("#dealer-location-list").empty();
                    var errorHTML = "<h2 class='text-center text-balise-orange'> No locations found, please try updating the search term</h2>";
                    $(errorHTML).appendTo("#dealer-location-list");
                }


            },
            error: function (e) {
                $("#dealer-location-list").empty();
                var ToAdd = "<div class='inline-flex justify-center items-center w-full h-full'>" +
                    "<div class='text-center text-white'> No Dealers Found </div></div>";
                $(ToAdd).appendTo("#dealer-location-list");
            }
        });
    };
    var generateDealerAccordionList = function (dealers) {
        var returnHTML = "";
        var accordionID = "dealer-list-accordion";
        var accordionHeader = '<div id="' + accordionID +'" class="w-full h-full" data-accordion="collapse" data-active-classes="bg-[#caa581] text-balise-black">';
        returnHTML += accordionHeader;
        var accordionItemsData = [];
        //var contactButtonHTML = '<a href="/contact">Contact</a>';
        //var contactButtonHTML = buildButtonHTML("/contact", "Contact","_blank");
        $.each(dealers, function (i, item) {
            var accordionHeadingId = "dealer-list-accordion-heading-" + i;
            var accordionBodyId = "dealer-list-accordion-body-" + i;
            var active = i == 0 ? true : false;
            var mapsSearchAddress = item.street + "," + item.city + "," + item.state + "," + item.country + "," + item.zipcode;
            var encodedAddress = encodeURIComponent(mapsSearchAddress);
            var directionsURL = "https://www.google.com/maps/search/?api=1&query=" + encodedAddress;
            var websiteURL = item.website;
            var websiteButtonHTML = buildDealerLinkHTML(websiteURL, "Visit Website", "_blank");
            var directionsButtonHTML = buildDealerButtonHTML(directionsURL, "Get Directions", "_blank");
            var contactDealerButtonHTML = buildContactDealerButton(item.dealerNumber, item.name, item.uniqueKey, "Contact Dealer");

            var accordionItemHeader = '<h2 class="rounded-none group/dealercard hover:bg-[#2a3336]" id="' + accordionHeadingId + '">' +
                '<button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-balise-orange border border-0 rounded-none border-[#caa581] hover:bg-[#2a3336] hover:text-balise-orange gap-3" data-accordion-target="' + accordionBodyId + '" aria-expanded="true" aria-controls="' + accordionBodyId + '">' +
                '<div class="w-full text-inherit hover:text-white flex flex-col flex-wrap justify-start items-start">' +
                '<span class="text-start font-transducer tracking-[2.5px] rte-balise-white">' + item.name +'</span>' +
                '<span class="text-start font-transducer tracking-[0.75px] mt-2 rte-balise-white">' + item.street +'</span>' +
                '<span class="text-start font-transducer tracking-[0.75px] -mt-2 rte-balise-white">' + item.city+', '+ item.state +' '+item.zipcode+ '</span>' +
                '<span class="text-start font-transducer tracking-[0.75px] hover:underline mt-2 rte-balise-white"><a href="tel:'+item.phone+'">' + item.phone + '</a></span>' +
                '</div>' +
                '<svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">' +
                '<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />' +
                '</svg>'+
                '</button>'+
                '</h2 >';
            var accordionItemBody = '<div class="rounded-none" id="' + accordionBodyId + '" class="hidden" aria-labelledby="' + accordionHeadingId + '">' +
                '<div class="p-5 bg-[#2a3336] flex flex-col items-start justify-center gap-2 justify-center rounded-none">' +
                directionsButtonHTML +
                contactDealerButtonHTML +
                websiteButtonHTML +
                '</div>' +
                '</div>';

            var accordionItem = accordionItemHeader + accordionItemBody;
            returnHTML += accordionItem; 

            var accordionItem = {
                headerId: accordionHeadingId,
                bodyId: accordionBodyId,
                active: active
            };
            accordionItemsData.push(accordionItem);
        });
        var accordionHederEnd = '</div>';
        returnHTML += accordionHederEnd;

        var accordionData = {
            accordionElementId: accordionID,
            accordionItems: accordionItemsData
        }

        return { hmtl: returnHTML, accordionData: accordionData }
    }
    var buildLocationAccordionConfig = function (accordionData) {
        const accordionElement = document.getElementById(accordionData.accordionElementId);
        const accordionItems = [];
        $.each(accordionData.accordionItems, function (i, item) {
            var triggerEl = document.getElementById(item.headerId);
            var targetEl = document.getElementById(item.bodyId);
            var accordionitem = {
                id: item.headerId,
                triggerEl: triggerEl,
                targetEl: targetEl,
                active: item.active
            };
            accordionItems.push(accordionitem);
        });

        const options = dealerAccordionOptions;
        const instanceOptions = {
            id: accordionData.accordionElementId,
            override: true
        };

        return {
            accordionElement: accordionElement,
            accordionItems: accordionItems,
            options: options,
            instanceOptions: instanceOptions
        }
    }
    var buildDealerButtonHTML = function (url, buttonText, target ="_self") {
        var returnHTML = "";
        returnHTML = '<a href="' + url + '" target="' + target +'" type="button" class="inline-flex items-center justify-between border border-white w-full text-sm font-medium max-h-12 overflow-hidden group" role="group">' +
            '<div class="btn-primary w-full whitespace-nowrap bg-[#2a3336] border-white text-white group-hover:bg-balise-orange group-hover:border group-hover:border-white group-hover:text-white">' + buttonText + '</div>' +
            '<div class="inline-flex py-3 px-4 border-l-[1px] border-white bg-[#2a3336] items-center text-sm font-medium group-hover:bg-balise-orange group-hover:border-white group-hover:text-white">' +
            '<svg class="w-4 h-4 bg-transparent text-white group-hover:fill-balise-black group-hover:stroke-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#caa581" viewBox="0 0 14 10">' +
            '<path class="group-hover:stroke-white" stroke="#caa581" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M1 5h12m0 0L9 1m4 4L9 9"></path>' +
            '</svg>' +
            '</div>' +
            '</a>';
        return returnHTML;
    }
    var buildDealerLinkHTML =  function (url, buttonText, target = "_self") {
        var returnHTML = "";
        returnHTML = '<a href="'+url+'" target="'+target+'" class="flex justify-start items-center">' +
            '<span class="font-gibson uppercase cursor-pointer text-balise-orange tracking-[4.5px] p-4 text-sm" >' + buttonText + '</span >' +
            '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#caa581" class="size-6">' +
            '<path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />' +
            '</svg>' +
            '</a >';
        return returnHTML;
    }
    var buildContactDealerButton = function (dealerNumber, dealerName, uniqueKey,buttonText) {
        var returnHTML = "";
        returnHTML = '<a type="button" data-dealer-number="' + dealerNumber + '" data-dealer-name="' + dealerName + '" data-unique-key="' + uniqueKey + '" class="contact-dealer-button flex justify-start items-center"  data-modal-target="contact-dealer-modal" data-modal-toggle="contact-dealer-modal">' +
            '<span class="font-gibson uppercase cursor-pointer text-balise-orange tracking-[4.5px] p-4 text-sm" >' + buttonText +'</span >' +
            '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#caa581" class="size-6">' +
            '<path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />' +
            '</svg>' +
            '</a >';
        return returnHTML;

    }

});

if (dealerMapElem != null) {
    window.addEventListener("DOMContentLoaded", function () {
        addScript(googleApiUrl)
    });
}

