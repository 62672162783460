﻿import $ from "jquery";

// Video play on Image overlay click
$(document).on('click', '.play-video', function (e) {
    e.preventDefault();

    var parent_video = $(this).parent().parent();

    stopAllYouTubeVideos();

    var $t = $(this);
    var $iframe = $t.parent().parent().find('iframe');

    //$('.grid-media-carousel').each(function () {
    //    var $iframeAll = $(this).find('iframe');
    //    if ($iframeAll && $iframeAll.length > 0) {
    //        for (var i = 0; i < $iframeAll.length; i++) {
    //            var oldSrc = $iframeAll[i].src;
    //            var newSrc = oldSrc.replace("&autoplay=1&enablejsapi=1", "")
    //            $iframeAll[i].src = newSrc
    //        }
    //    }

    //    //Array.prototype.forEach.call($iframeAll, iframe => {
    //    //    var oldSrc = iframe.src;
    //    //    var newSrc = oldSrc.replace("&autoplay=1&enablejsapi=1", "")
    //    //    iframe.src = newSrc
    //    //});
    //});

    //console.log($iframe);
    setTimeout(function () {
        runCommand('play', $iframe);
        if (!$t.parent().parent().find('iframe')[0].src.includes("&autoplay=1&enablejsapi=1")) {
            $t.parent().parent().find('iframe')[0].src += "&autoplay=1&enablejsapi=1";
        }

    }, 200);

    $t.parent().parent().addClass("video-playing");

    $t.parent().fadeOut(200); //hide the .image div

});

function pauseVideo(event) {
    var element = event.target;
    stopAllYouTubeVideos();
}
function stopAllYouTubeVideos() {
    var iframes = document.querySelectorAll('iframe');
    Array.prototype.forEach.call(iframes, function (iframe) {
        iframe.contentWindow.postMessage(JSON.stringify({
            event: 'command',
            func: 'pauseVideo'
        }), '*');
    });
}

function runCommand(cmd, $f) {
    var data = { method: cmd },
        url = $f.attr('src').split('?')[0];
    $f[0].contentWindow.postMessage(JSON.stringify(data), url);
}