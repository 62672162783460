import "./assets/fonts/fonts.css";
import '@splidejs/splide/dist/css/splide.min.css';
import "./styles/main.css";
import "./styles/layouts/blockgridlayout.css";
import "../css/index.css";

import "flowbite";
import _, { map } from 'underscore';
import "./scripts/global/cookie.js";
import "./scripts/global/navigationBar.js";
import "./scripts/modules/salesforceForms.js";
import "./scripts/modules/embedVideoRow.js";
import "./scripts/modules/boatLayout.js";
import "./scripts/modules/comparePage.js";
import "./scripts/modules/dealerLookup.js";
import "./scripts/modules/eventsPage.js";
import "./scripts/modules/heroVerticalSliderRow.js";
import "./scripts/modules/masthead.js";



import (/* webpackChunkName: "splide-carousel" */'./scripts/modules/carousel.js');
import (/* webpackChunkName: "accordian" */'./scripts/modules/accordion.js');


/** export lib functions */
export {
    playPause,
  } from "./scripts/modules/videoWithThumbnail.js";
export {showModal} from "./scripts/utilities/Util.js"